import axios from "axios";

export const useAuth = () => {
  const runtimeConfig = useRuntimeConfig();
  const api = axios.create({ baseURL: runtimeConfig.public.apiAuthUrl });

  function auth (url: string, accessToken: string) {
    return api.post<{ list_of_users: UserProfileI[] }>("portalgov/auth", { url, accessToken });
  }
  function validate (token: string) {
    return api.post<{ token: string }>("portalgov/auth/validate", { token });
  }

  return { auth, validate };
};
